import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {Observable} from 'rxjs';
import {TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {mergeMap} from 'rxjs/operators';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-request-cancelled-ammia',
  templateUrl: './request-cancelled-ammia.component.html',
  styleUrls: ['./request-cancelled-ammia.component.css']
})
export class RequestCancelledAmmiaComponent implements OnInit {
  dataRequest: any = [];
  clientList: any = [];
  policyList: any = [];
  dependentList: any = [];
  responseService: number;

  // Variables para typeahead (búsqueda de asegurados) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclInsuredSelected: any = {};

  lclRequestType: number; // Almacena el tipo de Solicitud que se enviará
  dataToSend: any = {};
  bsLeavingDate: Partial<BsDatepickerConfig>;

  // Variables para el multiselect de Dependientes ->
  dropdownSettingsDependents = {};
  dependentsSelectedItems: any = [];

  // tslint:disable-next-line:variable-name
  @ViewChild('typeaheadDirective') _typeahead: TypeaheadDirective;
  public get container(): TypeaheadContainerComponent {
    return this._typeahead._container;
  }
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService,
              private datePipe: DatePipe) {

    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getInsuredAsObservable(token))
    );
    this.shared.fUpdateRequestsCancellationObservable().subscribe(
      fRequestsCancellation => {
        if (fRequestsCancellation) {
          if (fRequestsCancellation && this.dataRequest.clientId === 0) {
            console.log('entra');
            this.dataToSend = {};
            this.responseService = 0;
            this.invokeFunctionGetClientByRequest();
          }
        }
      }
    );
  }

  ngOnInit(): void {
    console.log('se inicia componente cancelaciones');
    this.dataRequest.typeCancellation = 0;
    this.dataRequest.clientId = 0;
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    const currentDateMax30Days = new Date();
    currentDateMax30Days.setDate(currentDateMax30Days.getDate() + 30);
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    // Iniciamos configuración para DatePicker de Fecha de Baja ->
    this.bsLeavingDate = Object.assign({}, {
      adaptivePosition: true,
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
      maxDate: currentDateMax30Days
    });
    // Inicializamos configuración para dropdown de Dependientes ->
    this.dropdownSettingsDependents = {
      singleSelection: false,
      idField: 'dependentId',
      textField: 'name',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron dependientes',
      searchPlaceholderText: 'Buscar'
    };
  }

  getInsuredAsObservable(token: string): Observable<any> {
    const dataRequest = {
      clientId: this.dataRequest.clientId,
      search: token
    };
    return this.api.getFinderInsuredAmmia(dataRequest).pipe();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclInsuredSelected = e.item;
    console.log(this.lclInsuredSelected);
    this.invokeServiceGetPolicyByUser(this.lclInsuredSelected.userId, this.dataRequest.clientId);
    if (this.dataRequest.typeCancellation === 2) {
      this.invokeServiceGetDependentsByUser(this.lclInsuredSelected.userId);
    }
  }

  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequest.clientId = response[0].clientId;
          }
        }, error => {
          console.info(error);
        });
  }

  invokeServiceGetPolicyByUser(idUser: number, idClient: number) {
    const dataRequest = {
      userId: idUser,
      clientId: idClient
    };
    this.api.getPolicyClientsRequestByUser(dataRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.policyList = response;
          }
        }, error => {
          console.info(error);
        });

  }
  invokeFunctionSaveRequestInsuredCancelled() {
    console.log('se procesa solicitud de baja asegurado');
    if (!this.validateSelectedPolice(this.policyList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      return;
    }
    if (this.dataRequest.userId > 0 && this.dataRequest.clientId === 0 || this.dataRequest.leavingDate === undefined) {
      this.toastr.warning('Es necesario ingresar toda la infomación', 'notificación');
      return;
    }
    if (this.dataRequest.typeCancellation === 1) { // baja titulares
      const objToSend10 = {
        userId: this.lclInsuredSelected.userId,
        startDate: this.datePipe.transform(this.dataRequest.leavingDate, 'yyyy-MM-dd'),
        policyList: [],
        policySubranchCategories: this.policyList,
        clientId: this.dataRequest.clientId
        };
      console.log(objToSend10);
      this.responseService = 1;
      this.api.postRequestInsuredRemoveAmmia(objToSend10)
        .subscribe(response => {
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
            this.shared.fUpdateRequests.next(true);
            this.shared.fUpdateRequestsCancellation.next(false);
            this.responseService = 2;
            this.dataRequest.typeCancellation = 0;
          },  error => {
            console.error(error);
            if (error.status === 500) {
              this.responseService = 3;
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
    } else {
      // baja dependiente

      const objToSend11 = this.dependentsSelectedItems.map(x => (
        {
          userId: this.lclInsuredSelected.userId,
          startDate: this.datePipe.transform(this.dataRequest.leavingDate, 'yyyy-MM-dd'),
          dependentId: x.dependentId,
          policyList: [],
          policySubranchCategories: this.policyList,
          clientId: this.dataRequest.clientId
        }
      ));
      console.log(objToSend11);
      this.responseService = 1;
      this.api.postRequestDependentRemoveAmmia(objToSend11)
        .subscribe(
          response => {
            this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
            this.shared.fUpdateRequests.next(true);
            this.responseService = 2;
          }, error => {
            console.error(error);
            if (error.status === 500) {
              this.responseService = 3;
              if (error.error.message !== undefined) {
                this.toastr.warning(error.error.message, 'Notificación');
              } else {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              }
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          }
        );
    }
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }
  invokeFunctionChangeOperation(id: number) {
    this.dataRequest.typeCancellation = id;
    this.dataRequest.clientId = 0;
    this.dependentList = [];
    this.policyList = [];
    this.searchItemSelected = '';
    this.dependentsSelectedItems = [];
    this.shared.fUpdateRequestsCancellation.next(true);
  }
  invokeServiceGetDependentsByUser(idUser: number) {
    const dataRequest = {
      userId: idUser
    };
    this.dependentList = [];
    this.dataRequest.dependentId = 0;
    this.api.getDependentsByUserAmmia(dataRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.dependentList = response;
          } else {
            this.toastr.warning('El asegurado No tiene dependientes vinculados.', 'Notificación');
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionClear() {
    this.dataRequest.typeCancellation = 0;
    this.policyList = [];
    this.shared.fUpdateRequests.next(true);
    this.shared.fUpdateRequestsCancellation.next(true);
  }

  invokeValidationPolicy(event: any, id: number) {
    setTimeout(() => {
      console.log(this.policyList);
      if (event.target.checked) {
        console.log('entra 1');
        this.policyList[id].policyCategoriesList[0].selected = true;
      } else {
        console.log('entra 2');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.policyList[id].policyCategoriesList.length; i++) {
          this.policyList[id].policyCategoriesList[i].selected = false;
        }
      }
    }, 100);
  }
  invokeFunctionCheckCategory(c: any, id: number, ci: number, nameCheck: string, policyId: number ) {
    console.log(c);
    const name = nameCheck + ' ' + policyId;
    console.log(name);
    this.policyList[id].policyCategoriesList[ci].selected = !c;
    setTimeout(() => {
      console.log(this.policyList);
      this.validateDuplicatePolice(this.policyList);
    }, 100);
    const label = document.querySelector("label[for='" + name + "']");
    const radioButtons = label.querySelectorAll("input[type='checkbox']");
    if (c) {
      label.classList.remove('active');
      document.getElementById(name).removeAttribute('checked');
    } else {
      label.classList.add('active');
      document.getElementById(name).setAttribute('checked', 'checked');
    }
  }
  validateDuplicatePolice(policySubranchCategories: any) {
    const policySelected = [];
    let foundedPolicyRepetead = false;
    for (const policySubranchCategory of policySubranchCategories) {
      let policy;
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          if (policy === policySubranchCategory.idPolicy) {
            this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
            foundedPolicyRepetead = true;
            break;
          } else {
            policy = policySubranchCategory.idPolicy;
          }

          policy = policySubranchCategory.idPolicy;
        }
      }
    }
    return foundedPolicyRepetead;
  }

}
