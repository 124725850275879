<!--<div id="login-body" class="wrapper wrapper-full-page login-background" >
    <div class="content">
      <div class="login-box">
        <div class="left-side">
          <div>
            <div>Bienvenido a</div>
            <img src="../../assets/img/sx-logo-ammia-white.png">
          </div>
          <div class="version">V 3.0.14</div>
        </div>
        <div class="right-side">
          <form [formGroup]="loginForm" (ngSubmit)="authentication()">
            <div class="text-center">
              <p class="title">Inicio de sesión</p>
              <div class="description">Para comenzar, ingresa los datos de la cuenta con la que accedes al portal en linea</div>
            </div>
            <div>
              <div class="form-group form-group-sm has-feedback">
                <label for="email">Email</label>
                <input type="text" id="email" class="form-control input-no-border" formControlName="username" required>
                <span class="sx-iconsys-icon form-control-feedback">m</span>
              </div>
              <div class="form-group form-group-sm has-feedback">
                <label for="password">Contraseña</label>
                <input type="password" id="password" class="form-control input-no-border" formControlName="password">
                <span class="sx-iconsys-icon form-control-feedback">l</span>
              </div>
            </div>

            <div class="text-center">
              <div class="forgot-pass">
                <a href="javascript:;" (click)="openModalWithComponentRecovery()">Recuperar creedenciales</a>
              </div>
              <button type="submit" class="btn btn-sm btn-custom" [disabled]="loading">
                <strong>Acceder</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>-->



<!-- CONTAINER GENERAL -->
<main class="container-fluid container-general">


  <!--  Contenedor row index -->
  <div class="row  contenedor-inicio">

    <div class="col-12 col-lg-4 ">
      <div class="row inicio-sesion-contenedor inicio" >

        <div class="col-12 d-center">
          <img class="img-fluid" src="../../assets/img/ammia/logos/ammiaadmin-logo.svg" alt="logotipo">

        </div>

        <div class="col-12 d-center">
          <h3>Inicia sesión</h3>
        </div>

        <form [formGroup]="loginForm" >
          <div class="col-12 ">
            <div class="form-row">
              <div class="form-group">
                <label class="" id="email" for="email">Correo electrónico</label>
                <input type="text" class="form-control" id="email-i" name="email" formControlName="username" >
                <div class="invalid-feedback">
                  Please choose a username.
                </div>
              </div>
            </div>
            <div class="form-row gap-12">
              <div class="form-group">
                <label id="password"  for="password">Contraseña</label>
                <div class="d-flex gap-12">
                  <input [type]="banderaP" class="form-control ammiaAdmin-style" id=""name="password"  formControlName="password">
                  <button type="button"  class="enlace gris btn-link" (click)="invokeFunctionSeePassword()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_954_176603)">
                        <path d="M15.8806 7.4543C15.2365 6.05745 14.2931 4.81932 13.1172 3.82764L15.1386 1.8063L14.1952 0.862305L11.9999 3.05564C10.7848 2.35278 9.40359 1.98837 7.99991 2.0003C2.99991 2.0003 0.704581 6.1743 0.119247 7.4543C0.040673 7.62584 0 7.8123 0 8.00097C0 8.18965 0.040673 8.3761 0.119247 8.54764C0.763338 9.9445 1.70673 11.1826 2.88258 12.1743L0.861914 14.1956L1.80458 15.1383L3.99991 12.945C5.21501 13.6478 6.59624 14.0122 7.99991 14.0003C12.9999 14.0003 15.2952 9.82631 15.8806 8.5463C15.959 8.37496 15.9996 8.18874 15.9996 8.0003C15.9996 7.81187 15.959 7.62565 15.8806 7.4543ZM1.33325 8.00764C1.83325 6.91097 3.79525 3.33364 7.99991 3.33364C9.04631 3.32752 10.079 3.57164 11.0119 4.04564L9.83525 5.2223C9.1952 4.79737 8.42785 4.60697 7.6634 4.6834C6.89895 4.75984 6.18449 5.0984 5.64125 5.64164C5.09801 6.18488 4.75944 6.89934 4.68301 7.66379C4.60658 8.42824 4.79698 9.19559 5.22191 9.83564L3.83591 11.2216C2.7695 10.3532 1.91383 9.25436 1.33325 8.00764ZM9.99991 8.0003C9.99991 8.53074 9.7892 9.03945 9.41413 9.41452C9.03906 9.78959 8.53035 10.0003 7.99991 10.0003C7.70292 9.99916 7.41005 9.93078 7.14325 9.8003L9.79991 7.14364C9.93039 7.41044 9.99876 7.70331 9.99991 8.0003ZM5.99991 8.0003C5.99991 7.46987 6.21063 6.96116 6.5857 6.58609C6.96077 6.21102 7.46948 6.0003 7.99991 6.0003C8.29691 6.00145 8.58978 6.06983 8.85658 6.2003L6.19991 8.85697C6.06944 8.59017 6.00106 8.2973 5.99991 8.0003ZM7.99991 12.667C6.95352 12.6731 5.92082 12.429 4.98791 11.955L6.16458 10.7783C6.80463 11.2032 7.57198 11.3936 8.33643 11.3172C9.10088 11.2408 9.81534 10.9022 10.3586 10.359C10.9018 9.81573 11.2404 9.10127 11.3168 8.33682C11.3932 7.57237 11.2028 6.80502 10.7779 6.16497L12.1632 4.77964C13.2318 5.64885 14.0879 6.75034 14.6666 8.0003C14.1572 9.10497 12.1946 12.667 7.99991 12.667Z" />
                      </g>
                      <defs>
                        <clipPath id="clip0_954_176603">
                          <rect width="16" height="16" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
          <div class="col-8 d-column-center gap-12">
            <button type="button" class="btn primario lg mb-4" href="javascript:;"  [disabled]="loading" (click)="authentication()">
              <img  class="icono-sm"  src="../../assets/img/ammia/iconos/user-azul.svg" alt="checked">
              Iniciar sesión
            </button>
            <div class="version">V 1.0.0</div>
          </div>

        <div class="col-12 col-lg-10 d-between ">
          <div class="d-flex gap-8 checkbox-row">
            <input type="checkbox" name="recuerdame" class="d-flex" [(ngModel)]="rememberMe" >
            <label >Recuérdame</label>
          </div>


          <a href="javascript:;" >
            <button type="button" data-toggle="modal" href="" class="enlace  btn-link"
                    (click)="openModalWithComponentRecovery()">
              ¿Olvidaste tu contraseña?
            </button>
          </a>
        </div>

      </div>
      <!-- Fin Segundo row: col-container tareas -->
    </div>
    <!-- Fin de la columna "tareas" -->


    <!-- Columna "foto" -->
    <div class="d-none d-lg-flex col p-0 foto-inicio-container">
      <img class="foto-inicio" src="../../assets/img/ammia/imagenes/foto-inicio.jpg" alt="foto inicio">
    </div>
    <!-- Fin de la columna "foto" -->

  </div>
  <!--End  Contenedor row index -->
</main>
<!--TERMINA CONTAINER GENERAL -->










<ng-template #templateRestorePassword>
  <div class="modal-body text-center">
    <p>¿ESTÁ SEGURO QUE DESEA REESTABLECER LA CONTRASEÑA DEL ASEGURADO?</p>
    <button type="button" class="btn btn-custom  btn-srx" (click)="bsModalRef.hide()" >ACEPTAR</button>
  </div>
</ng-template>
