import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {Observable} from 'rxjs';
import {TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {mergeMap} from 'rxjs/operators';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-request-cancelled-car-ammia',
  templateUrl: './request-cancelled-car-ammia.component.html',
  styleUrls: ['./request-cancelled-car-ammia.component.css']
})
export class RequestCancelledCarAmmiaComponent implements OnInit {

  responseService: number;
  dataRequest: any = [];
  clientList: any = [];
  policyList: any = [];
  policiesList: any = [];
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclCarSelected: any = {};
  bsLeavingDate: Partial<BsDatepickerConfig>;

  // tslint:disable-next-line:variable-name
  @ViewChild('typeaheadDirective') _typeahead: TypeaheadDirective;
  public get container(): TypeaheadContainerComponent {
    return this._typeahead._container;
  }
  constructor(private api: ApiService,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              public shared: SharedService) {
    this.dataSource = new Observable((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getCarAsObservable(token))
    );
    this.shared.fUpdateRequestsCancellationCarObservable().subscribe(
      fRequestsCancellationCar => {
        if (fRequestsCancellationCar) {
          console.log('entra');
          this.responseService = 0;
          this.invokeFunctionGetClientByRequest();
        }
      }
    );
  }

  ngOnInit(): void {
    this.responseService = 0;
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    const currentDateMax30Days = new Date();
    currentDateMax30Days.setDate(currentDateMax30Days.getDate() + 30);
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    // Iniciamos configuración para DatePicker de Fecha de Baja ->
    this.bsLeavingDate = Object.assign({}, {
      adaptivePosition: true,
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
      maxDate: currentDateMax30Days
    });
  }

  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequest.clientId = response[0].clientId;
            // this.invokeFunctionGetPoliciesByClient();
            this.shared.gCurrentCustomerId.next(this.dataRequest.clientId);
          }
        }, error => {
          console.info(error);
        });
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclCarSelected = e.item;
    this.invokeServiceForGetPolicies(this.lclCarSelected.carId);
  }

  private invokeServiceForGetPolicies(carId?) {
    this.policiesList = [];
    this.api.getPoliciesClientCarId(carId)
      .subscribe(
        response => {
          this.policiesList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  getCarAsObservable(token: string): Observable<any> {
    return this.api.getFinderCar(token).pipe();
  }

  invokeFunctionGetPoliciesByClient() {
    this.shared.gCurrentCustomerId.next(this.dataRequest.clientId);
  }

  /// me falta cambiar el componente fecha y integrar el servicio de baja de carro

  invokeFunctionSaveRequestCarCancelled() {
    console.log(this.dataRequest);
    this.shared.gCurrentCustomerId.next(this.dataRequest.clientId);
    if (!this.validateSelectedPolice(this.policiesList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      return;
    }
    const objToSend10 = {
      carId: this.lclCarSelected.carId,
      startDate: this.datePipe.transform(this.dataRequest.leavingDate, 'yyyy-MM-dd'),
      policySubranchCategories: this.policiesList,
    };
    this.responseService = 1;
    this.api.postCancellationVehicleRequestData(objToSend10)
      .subscribe(response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequests.next(true);
          this.shared.fUpdateRequestsCancellationCar.next(false);
          this.responseService = 2;
        }, error => {
          console.error(error);
          this.responseService = 3;
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }
  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }
  invokeFunctionClear() {
    this.responseService = 0;
    this.shared.fUpdateRequestsCancellationCar.next(false);
    this.shared.fUpdateRequests.next(true);
  }
}
