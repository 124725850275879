import {Component, OnInit, TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {SharedService} from '../shared-service.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Documents} from "../upload-certificate/documents";
import {InvoiceType} from "../new-request-car/InvoiceType";

@Component({
  selector: 'app-request-add-car-ammia',
  templateUrl: './request-add-car-ammia.component.html',
  styleUrls: ['./request-add-car-ammia.component.css']
})
export class RequestAddCarAmmiaComponent implements OnInit {
  responseService: number;
  dataToSend: any = [];
  clientList: any = [];
  policyList: any = [];
  policiesList: any = [];
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclCarSelected: any = {};
  bsLeavingDate: Partial<BsDatepickerConfig>;
  gendersCatalog: any = [];

  /* NUEVOS */
  dataSourceMarca: any;
  dataSourceModelo: any;
  dataSourceVersion: any;
  searchMarcaSelected: any = {};
  searchVehicleTypeSelected: string;
  searchModeloSelected: any = {};
  facturevalue: any;
  modalAdaptationtRef: BsModalRef;
  monthActual: number;
  yearActual: number;
  bsEntryDate: Partial<BsDatepickerConfig>;
  dataSourceUse: any = [];

  public fileUpload: File;
  public fileUploadAdaptation: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];
  uploadedFilesInvoice: Array<Documents> = []; // Documentos del cliente
  uploadedFilesInvoiceAdaptation: Array<Documents> = []; // Documentos del cliente
  addTitular: boolean;
  addBenefit: boolean;
  attachAdaptatio = false;
  invoiceAdaptationList: any = [];
  adaptation: any = {}; // Almacena los datos del dependiente dentro del apartado de Asegurados
  constructor(private api: ApiService,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              public shared: SharedService,
              private modalService: BsModalService) {
    this.shared.fUpdateRequestAddCarObservable().subscribe(
      fRequestsAddCar => {
        if (fRequestsAddCar) {
          this.responseService = 0;
          this.invokeFunctionGetClientByRequest();
          this.invokeServiceGetUseVehicleList();
          /*NUEVOS*/
          this.invokeMakesCatalogList();
          this.invokeModelYearCatalogList();
        }
      }
    );
  }

  ngOnInit(): void {
    this.yearActual = new Date().getFullYear();
    this.monthActual = new Date().getMonth();
    this.addTitular = false;
    this.addBenefit = false;

    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    const currentDateMax30Days = new Date();
    currentDateMax30Days.setDate(currentDateMax30Days.getDate() + 30);
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    this.bsEntryDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
      maxDate: currentDateMax30Days
    });
  }
  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataToSend.clientId = response[0].clientId;
            // this.invokeFunctionGetPoliciesByClient();
            this.shared.gCurrentCustomerId.next(this.dataToSend.clientId);
            this.invokeServiceForGetPolicies();
            this.invokeServiceForGetGendersCatalog();
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionGetPoliciesByClient() {
    this.shared.gCurrentCustomerId.next(this.dataToSend.clientId);
  }

  private invokeServiceForGetPolicies(carId?) {
    this.api.getPoliciesClientCarMin()
      .subscribe(
        (data) => {
          this.policiesList = data;
        }, error => {
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
        }
      );
  }

  private invokeServiceForGetGendersCatalog() {
    this.api.getGendersCatalog()
      .subscribe(
        response => {
          this.gendersCatalog = response;
          this.dataToSend.gender = this.gendersCatalog[0].genderId;
        }, error => {
          console.error(error);
        }
      );
  }

  typeaheadOnSelectMarca(marca): void {
    this.searchMarcaSelected = marca;
    if (this.dataToSend.mark !== undefined && this.dataToSend.model !== undefined) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }
  }
  invokeCarVersion(dataSearch) {
    this.api.getCarVersionCatalogist(dataSearch).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceVersion = response;
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeMakesCatalogList() {
    const data = {
      isMotorbike: false
    };
    this.api.getMakeCatalogist(data).then(
      (response: any) => {
        this.dataSourceMarca = response;

      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  invokeModelYearCatalogList() {
    const data = {
      isMotorbike: false
    };
    this.api.getModelYearCatalogist(data).then( // Si todo bien, se guarda
      (response: any) => {
        this.dataSourceModelo = response;
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.error(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      }
    );
  }

  typeaheadOnSelectModelo(modelo): void {
    this.searchModeloSelected = modelo;
    if (this.dataToSend.mark !== undefined && this.dataToSend.model !== undefined) {
      const searchVersion = {
        shortMake: this.searchMarcaSelected.id,
        longMake: this.searchMarcaSelected.name,
        modelYear: this.searchModeloSelected.id
      };

      this.invokeCarVersion(searchVersion);
    }
  }

  public changeValorFactura(item) {
    this.facturevalue = item;
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesInvoice = [];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUpload).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUpload.name;
          this.uploadedFilesInvoice.push(documents);
        }
      );
    }
  }

  // Convertimos la imágen o PDF a base64
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  invokeServiceGetUseVehicleList() {
    this.api.getUseCatalog().then((data: any) => {
      if (data != null) {
        this.dataSourceUse = data;
      }
    }, error => {
      console.error(error.status);
      console.error(error.statusText);
      console.error(error.message);
    });
  }

  onChangeAddTitular(event) {
    this.addTitular = event;

    if (!this.addTitular) {
      delete this.dataToSend.name;
      delete this.dataToSend.firstName;
      delete this.dataToSend.lastName;
      delete this.dataToSend.email;
    }
  }

  onChangeAddBenefit(event) {
    this.addBenefit = event;

    if (!this.addBenefit) {
      delete this.dataToSend.rfc;
      delete this.dataToSend.tradename;
    }
  }
  public activateAdaptationRegistrationInCar() {
    this.attachAdaptatio = true;
  }

  public showAdaptations(template: TemplateRef<any>) {
    this.modalAdaptationtRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
  }
  public deleteAdaptation(index) {
    this.invoiceAdaptationList.splice(index, 1);
  }

  onChangeInputFileAdaptation(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadAdaptation = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesInvoiceAdaptation = [];
    if (0 > this.fileTypes.indexOf(this.fileUploadAdaptation.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUploadAdaptation).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUploadAdaptation.name;
          this.uploadedFilesInvoiceAdaptation.push(documents);
        }
      );
    }
  }

  public addNewAdaptation() {
    let objToSend01 = {};
    console.log(this.dataToSend.upType);
    console.log(this.adaptation);
    if (this.adaptation.name === undefined
      || this.adaptation.value === undefined
      || this.uploadedFilesInvoiceAdaptation.length === 0) {
      this.toastr.warning('Por favor llene todos los campos requeridos');
      return;
    }
    setTimeout(() => {
      objToSend01 = {
        name: this.adaptation.name,
        value: this.adaptation.value,
        nameFileInvoice: this.uploadedFilesInvoiceAdaptation[0].name,
        baseInvoice: this.uploadedFilesInvoiceAdaptation[0].base64,
        invoiceType: InvoiceType.ADAPTATION_INVOICE
      };

      this.invoiceAdaptationList.push(objToSend01);

      this.adaptation.name = undefined;
      this.adaptation.value = undefined;
      this.uploadedFilesInvoiceAdaptation = [];
      this.fileUploadAdaptation = null;
    }, 10);
  }

  // me quede aqui tengo que verificar y armar el proceso de guardado de la solicitud de alta de vehiculo,
  //  ya compile servicio falta agregar loading de ammia y que recrgue la pagina necesito implementaar observador
  invokeFunctionSaveRequestAddCar() {
    if (this.validateDuplicatePolice(this.policiesList)) {
      return;
    }
    if (!this.validateSelectedPolice(this.policiesList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      return;
    }

    if (!this.validateDataIfHaveDriver(this.dataToSend)) {
      this.toastr.warning('Si el vehículo tiene propietario o conductor habitual, deberá llenar los datos solicitados');
      return;
    }

    if (!this.validateDataIfHaveBenefit(this.dataToSend)) {
      this.toastr.warning('Si el vehículo tiene beneficiario, deberá llenar los datos solicitados');
      return;
    }
    if (this.adaptation.name !== undefined
      && this.adaptation.value !== undefined
      && this.uploadedFilesInvoiceAdaptation.length !== 0) {
      const lastAdptation = {
        name: this.adaptation.name,
        value: this.adaptation.value,
        nameFileInvoice: this.uploadedFilesInvoiceAdaptation[0].name,
        baseInvoice: this.uploadedFilesInvoiceAdaptation[0].base64,
        invoiceType: InvoiceType.ADAPTATION_INVOICE
      };

      this.invoiceAdaptationList.push(lastAdptation);
      this.adaptation = {};
      this.uploadedFilesInvoiceAdaptation = [];

    }
    const objToSend00 = {
      mark: this.dataToSend.mark,
      model: String(this.dataToSend.model),
      version: this.dataToSend.version,
      plate: this.dataToSend.plate,
      serialNumber: this.dataToSend.serialNumber,
      engineNumber: this.dataToSend.engineNumber,
      valueInvoice: this.dataToSend.valueInvoice,
      baseFileInvoice: this.uploadedFilesInvoice.length === 0 ? '' : this.uploadedFilesInvoice[0].base64,
      nameFileInvoice: this.uploadedFilesInvoice.length === 0 ? '' : this.uploadedFilesInvoice[0].name,
      startDate: this.datePipe.transform(this.dataToSend.startDate, 'yyyy-MM-dd'),
      policySubranchCategories: this.policiesList,
      fileInvoiceList: this.invoiceAdaptationList,
      name: this.dataToSend.name,
      firstName: this.dataToSend.firstName,
      lastName: this.dataToSend.lastName,
      email: this.dataToSend.email,
      use: String(this.dataToSend.use),
      tradename: this.dataToSend.tradename,
      rfc: this.dataToSend.rfc,
    };
    console.log(objToSend00);
    console.log(this.shared.gCurrentCustomerId);
    this.responseService = 1;
    this.api.postRequestCarAdd(objToSend00)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.responseService = 2;
          this.shared.fUpdateRequests.next(true);
        }, error => {
          this.responseService = 3;
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
              this.invoiceAdaptationList = [];
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }

  validateDuplicatePolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let foundedPolicyRepetead = false;
    if (policySubranchCategories === null || policySubranchCategories.length === 0 || policySubranchCategories === undefined) {
      this.toastr.warning('No se ha seleccionado ninguna póliza');
      return ;
    }

    for (const policySubranchCategory of policySubranchCategories) {
      let policy;
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          if (policy === policySubranchCategory.idPolicy) {
            this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
            foundedPolicyRepetead = true;
            break;
          } else {
            policy = policySubranchCategory.idPolicy;
          }

          policy = policySubranchCategory.idPolicy;
        }
      }
    }
    return foundedPolicyRepetead;
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  validateDataIfHaveDriver(dataToSend: any): boolean {
    if (
      this.addTitular &&
      (
        this.dataToSend.name === null ||
        this.dataToSend.name === undefined ||
        this.dataToSend.name === '' ||
        this.dataToSend.firstName === null ||
        this.dataToSend.firstName === undefined ||
        this.dataToSend.firstName === '' ||
        this.dataToSend.lastName === null ||
        this.dataToSend.lastName === undefined ||
        this.dataToSend.lastName === '' ||
        this.dataToSend.email === null ||
        this.dataToSend.email === undefined ||
        this.dataToSend.email === ''
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateDataIfHaveBenefit(dataToSend: any): boolean {
    if (
      this.addBenefit &&
      (
        this.dataToSend.tradename === null ||
        this.dataToSend.tradename === undefined ||
        this.dataToSend.tradename === '' ||
        this.dataToSend.rfc === null ||
        this.dataToSend.rfc === undefined ||
        this.dataToSend.rfc === '')
    ) {
      return false;
    } else {
      return true;
    }
  }

  invokeFunctionClear() {
    this.shared.fUpdateRequestsAddCar.next(true);
  }
}
