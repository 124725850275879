<div class="form-row mb-5" >
  <div class="form-group">
    <label class="mb-3" >Selecciona si es asegurado o beneficiario</label>
    <div class="d-start px-md-4 gap-16 ">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="tipodeUsuarioBaja" id="tipodeUsuarioBaja1" [value]="1"  onchange="radioButton(this); porTitularBaja()" [(ngModel)]="dataRequest.typeCancellation" (click)="invokeFunctionChangeOperation(1)">
        <label class="form-check-label  mx-0" for="tipodeUsuarioBaja1">
          <p class="">Asegurado titular</p>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="tipodeUsuarioBaja" id="tipodeUsuarioBaja2" [value]="2" onchange="radioButton(this); porBeneficiarioBaja() " [(ngModel)]="dataRequest.typeCancellation" (click)="invokeFunctionChangeOperation(2)">
        <label class="form-check-label mx-0" for="tipodeUsuarioBaja2">
          <p class="">Beneficiario del titular</p>
        </label>
      </div>

    </div>
  </div>
</div>



<!-- Por titular BAJA-->
<div id="porTitularBaja" class="seleccionasteAlta d-none" *ngIf="responseService == 0">
  <form action="" *ngIf="dataRequest.typeCancellation == 1">
    <!--- Buscar cliente-->
    <div class="form-row">
      <div class="form-group">
        <label >Razón Social</label>
        <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataRequest.clientId"
                name="client" required >
          <option *ngFor="let item of clientList" [value]="item.clientId"
          >{{item.name}}</option>
        </select>
      </div>
    </div>
    <!-- Buscar asegurado titular -->
    <div class="form-row">
      <div class="form-group">
        <label>Buscar asegurado</label>
        <ng-template #customItemTemplate let-model="item" let-index="index">
            <p >{{ model.name}}</p>
        </ng-template>
        <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
          <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
        <span *ngIf="match.isHeader()">
            {{match}}
        </span>
            <div class="global-search-option"
                 (click)="container.selectMatch(match, $event)">
              <ng-template [ngIf]="!match.isHeader()">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: match.item, index: i, match: match, query: query}"></ng-template>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
        <div class="search-container d-between w-100">
          <!--  <input type="text" class=" form-control ammiaAdmin-style" placeholder="Busca por nombre de asegurado">
          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">-->
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="name"
                 placeholder="Buscar…"
                 class="form-control"
                 name="insured"
                 autocomplete="off"
                 [typeaheadItemTemplate]="customItemTemplate"
                 [optionsListTemplate]="customListTemplate"
                 #typeaheadDirective="bs-typeahead">

            <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">

          <div *ngIf="typeaheadLoading">Loading</div>

        </div>
      </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Selecciona la póliza maestra</h4>
      <hr>
    </div>
    <!-- Seleccionar poliza maestra -->
    <div class="form-row">
      <div class="form-group" *ngIf="policyList.length > 0">
        <div class="d-between">
          <div class="form-check form-check-inline mb-3" *ngFor="let p of policyList">
            <input class="form-check-input" type="checkbox" name="polizaMaestraSolicitud" id="polizaMaestraSolicitud {{p.idPolicy}}"
                   value="option{{p.policyId}}"
                   onchange="pruebaCheckbox(this)" >
            <label class="form-check-label poliza-maestra mx-0" for="polizaMaestraSolicitud {{p.idPolicy}}">

              <h4 class="">{{p.subranch | titlecase}} <br> {{p.numberPolicy}}</h4>
              <h5>{{p.insurance}}</h5>

              <div class="d-column-start mt-3">
                <div class="form-check form-check-inline" *ngFor="let c of p.policyCategoriesList">
                  <input class="form-check-input" type="checkbox" name="directoresSolicitud1" id="directoresSolicitud{{c.idCategory}}" onchange="radioButton(this)"
                         [(ngModel)]="c.selected" [ngModelOptions]="{standalone: false}" >
                  <label class="form-check-label  mx-0" for="directoresSolicitud{{c.idCategory}}">
                    <p class="">{{c.category | titlecase}}</p>
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group form-group-sm">
      <label class="control-label">Fecha de baja</label>
      <div class="input-group">
        <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
        <input type="text"
               placeholder="00-00-0000"
               class="form-control"
               bsDatepicker
               [bsConfig]="bsLeavingDate"
               [(ngModel)]="dataRequest.leavingDate"
               name="leavingDate"
               required>
      </div>
    </div>
    <!--<div class="form-row">
      <div class="form-group col-md-6">
        <label for="fecha">Fecha de baja</label>
        <input type="date" class="form-control ammiaAdmin-style" id="fechaAlta" name="fechaAlta" placeholder="Selecciona una fecha">
      </div>
    </div>-->

    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario" id="btnGenerarSolicitudGeneral" type="button"
              (click)="invokeFunctionSaveRequestInsuredCancelled()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>
  </form>
</div>
<!-- Por Beneficiario BAJA-->
<div id="porBeneficiarioBaja" class="seleccionasteAlta d-none" *ngIf="responseService == 0">
  <form action="" *ngIf="dataRequest.typeCancellation == 2">
    <!--- Buscar cliente-->
    <div class="form-row">
      <div class="form-group">
        <label >Razón Social</label>
        <select class="form-select ammiaAdmin-style  mb-3" [(ngModel)]="dataRequest.clientId"
                name="clientId" required >
          <option *ngFor="let item of clientList" [value]="item.clientId"
          >{{item.name}}</option>
        </select>
      </div>
    </div>
    <!-- Buscar asegurado titular -->
    <div class="form-row">
      <div class="form-group">
        <label>Buscar asegurado</label>
        <ng-template #customItemTemplate let-model="item" let-index="index">
          <p >{{ model.name}}</p>
        </ng-template>
        <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
          <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
        <span *ngIf="match.isHeader()">
            {{match}}
        </span>
            <div class="global-search-option"
                 (click)="container.selectMatch(match, $event)">
              <ng-template [ngIf]="!match.isHeader()">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: match.item, index: i, match: match, query: query}"></ng-template>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
        <div class="search-container d-between w-100">
          <!--  <input type="text" class=" form-control ammiaAdmin-style" placeholder="Busca por nombre de asegurado">
          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">-->
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="name"
                 placeholder="Buscar…"
                 class="form-control"
                 name="insured"
                 autocomplete="off"
                 [typeaheadItemTemplate]="customItemTemplate"
                 [optionsListTemplate]="customListTemplate"
                 #typeaheadDirective="bs-typeahead">

          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">

          <div *ngIf="typeaheadLoading">Loading</div>

        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label>Seleccionar beneficiario</label>
        <!--<select class="form-select ammiaAdmin-style  mb-3"  [(ngModel)]="dataRequest.dependentId"
                name="relationshipDep" required >
          <option *ngFor="let item of dependentList" [value]="item.dependentId"
          >{{item.name}}</option>
        </select>-->
        <div class="row">
          <div class="col-xs-12">
            <ng-multiselect-dropdown
              [placeholder]="'Dependientes seleccionados'"
              [data]="dependentList"
              [(ngModel)]="dependentsSelectedItems"
              [settings]="dropdownSettingsDependents"
              name="dependents">
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Selecciona la póliza maestra</h4>
      <hr>
    </div>
    <!-- Seleccionar poliza maestra -->
    <div class="form-row">
      <div class="form-group" *ngIf="policyList.length > 0">
        <div class="d-between  no-wrap scroll">
          <div class="form-check form-check-inline" *ngFor="let p of policyList">
            <input class="form-check-input" type="checkbox" name="polizaMaestraSolicitud" id="polizaMaestraSolicitud {{p.idPolicy}}"
                   value="option{{p.policyId}}"
                   onchange="pruebaCheckbox(this)"
            >
            <label class="form-check-label poliza-maestra mx-0" for="polizaMaestraSolicitud {{p.idPolicy}}">

              <h4 class="">{{p.subranch | titlecase}} <br> {{p.numberPolicy}}</h4>
              <h5>{{p.insurance}}</h5>

              <div class="d-column-start mt-3">
                <div class="form-check form-check-inline" *ngFor="let c of p.policyCategoriesList">
                  <input class="form-check-input" type="checkbox" name="directoresSolicitud1" id="directoresSolicitud{{c.idCategory}}" onchange="radioButton(this)"
                         [(ngModel)]="c.selected" [ngModelOptions]="{standalone: false}" >
                  <label class="form-check-label  mx-0" for="directoresSolicitud{{c.idCategory}}">
                    <p class="">{{c.category | titlecase}}</p>
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group form-group-sm">
      <label class="control-label">Fecha de baja</label>
      <div class="input-group">
        <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
        <input type="text"
               placeholder="00-00-0000"
               class="form-control"
               bsDatepicker
               [bsConfig]="bsLeavingDate"
               [(ngModel)]="dataRequest.leavingDate"
               name="leavingDate"
               required>
      </div>
    </div>

    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario" id="btnGenerarSolicitudCancel" type="button"
              (click)="invokeFunctionSaveRequestInsuredCancelled()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>
  </form>

</div>


<div class="w-100 d-column-center p-3 p-md-5" *ngIf="responseService == 2">
  <a href="javascript:;"   aria-controls="offcanvasRight">
    <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
  </a>

  <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>


  <div class="d-center w-100 mt-5">
    <button class="btn primario" id="btnGenerarSolicitudVehiculoc" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
      <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
      Entendido
    </button>
  </div>
</div>

<div class=""  *ngIf="responseService == 1">
  <div class="w-100 d-column-center">
    <a href="javascript:;"  aria-controls="offcanvasRight" >
      <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <div>
      <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
    </div>
  </div>
</div>
<div  *ngIf="responseService == 3">
  <a href="javascript:;"   aria-controls="offcanvasRight" >
    <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
  </a>
  <div>
    <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
    </p>
  </div>
  <div class="d-center w-100 mt-5">
    <button class="btn primario" id="btnGenerarSolicitudVehiculocc" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
      <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
      Cerrar
    </button>
  </div>
</div>


